import { MoonStar, Sun } from "lucide-react";

import { Theme } from "./theme-provider.tsx";
import { cn } from "./utils/cn.ts";

const iconThemeMap = new Map([
  [Theme.LIGHT, Sun],
  [Theme.DARK, MoonStar],
]);

interface ThemeToggleIconProps {
  theme: Theme;
  checked: boolean;
  className?: string;
}

export const ThemeToggleIcon = ({
  theme,
  checked,
  className,
}: ThemeToggleIconProps) => {
  const Component = iconThemeMap.get(theme);
  let colorClassName = "";
  if (checked) {
    switch (theme) {
      case Theme.LIGHT:
        colorClassName = "text-yellow-500";
        break;
      case Theme.DARK:
        colorClassName = "text-indigo-500";
        break;
    }
  }

  if (Component) {
    return <Component key={theme} className={cn(colorClassName, className)} />;
  }

  return <></>;
};

/** @alias */
export default ThemeToggleIcon;
